{
  "version": "0.12.26",
  "license": "MIT",
  "repository": {
    "type": "git",
    "url": "https://github.com/100mslive/web-sdks.git",
    "directory": "packages/hms-video-store"
  },
  "main": "dist/index.cjs.js",
  "module": "dist/index.js",
  "typings": "dist/index.d.ts",
  "files": [
    "dist",
    "src"
  ],
  "engines": {
    "node": ">=12"
  },
  "exports": {
    ".": {
      "require": "./dist/index.cjs.js",
      "import": "./dist/index.js",
      "default": "./dist/index.js"
    }
  },
  "sideEffects": false,
  "scripts": {
    "prestart": "rm -rf dist && yarn types:build",
    "start": "concurrently \"yarn dev\" \"yarn types\"",
    "dev": "node ../../scripts/dev",
    "build:only": "node ../../scripts/build",
    "build": "yarn build:only && yarn types:build",
    "types": "tsc -w",
    "types:build": "tsc -p tsconfig.json",
    "format": "prettier --write src/**/*.ts",
    "test": "jest --maxWorkers=1",
    "test:watch": "jest --watch",
    "test:coverage": "jest --coverage",
    "lint": "eslint -c ../../.eslintrc .",
    "lint:fix": "yarn lint --fix",
    "prepare": "yarn build",
    "size": "size-limit",
    "analyze": "size-limit --why",
    "docs": "rm -rf ./docs && typedoc && rm -f ./docs/README.md && mkdir ./docs/home &&mv ./docs/modules.md ./docs/home/content.md && node ../../scripts/docs-store && npx prettier --write './docs/**/*'"
  },
  "name": "@100mslive/hms-video-store",
  "author": "100ms",
  "dependencies": {
    "eventemitter2": "^6.4.9",
    "immer": "^9.0.6",
    "lodash.isequal": "^4.5.0",
    "reselect": "4.0.0",
    "sdp-transform": "^2.14.1",
    "ua-parser-js": "^1.0.1",
    "uuid": "^8.3.2",
    "webrtc-adapter": "^8.0.0",
    "zustand": "3.5.7"
  },
  "devDependencies": {
    "@types/dom-screen-wake-lock": "^1.0.1",
    "@types/lodash.isequal": "^4.5.8",
    "@types/sdp-transform": "^2.4.4",
    "@types/ua-parser-js": "^0.7.36",
    "@types/uuid": "^8.3.0",
    "jest-canvas-mock": "^2.3.1",
    "jsdom-worker": "^0.3.0",
    "tslib": "^2.2.0"
  },
  "description": "@100mslive Core SDK which abstracts the complexities of webRTC while providing a reactive store for data management with a unidirectional data flow",
  "keywords": [
    "video",
    "webrtc",
    "conferencing",
    "100ms"
  ],
  "gitHead": "b0f9937dca2c0201d123fb3ca25ca4ede69ae91c"
}
